<!--
 * @Author: your name
 * @Date: 2021-12-03 07:49:45
 * @LastEditTime: 2021-12-28 15:53:26
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page1.vue
-->
<template>
  <div class="fx101-page1">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx301/1.png"
        alt="上太科技"
      />
    </div>
    <div class="text-div">
      <span class="model-span din">ST-FX301</span>
      <span class="label-span">太阳能捕虫器</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page1 {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  .img-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      height: 100%;
      // width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-80%);
      object-fit: contain;
      // object-fit: cover;
    }
  }
  .text-div {
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .model-span {
      font-size: 84px;
      font-weight: 500;
      color: #ffffff;
    }
    .label-span {
      font-size: 36px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page1 {
    position: relative;
    height: auto;
    overflow: hidden;
    .img-div {
      position: relative;
      width: 100%;
      margin: auto;
      height: unset;
      display: flex;
      justify-content: center;
      img {
        height: unset;
        width: 86%;
        position: relative;
        margin: auto;
        left: unset;
        transform: translateX(-20%);
        object-fit: contain;
      }
    }
    .text-div {
      position: absolute;
      top: $mobile-header-height + 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .model-span {
        font-size: 0.76rem;
      }
      .label-span {
        font-size: 0.33rem;
        margin-top: 10px;
      }
    }
  }
}
</style>
