<!--
 * @Author: your name
 * @Date: 2021-12-03 07:47:09
 * @LastEditTime: 2021-12-28 16:51:12
 * @LastEditors: Please set LastEditors
 * @Description: 捕虫器fx101专题
 * @FilePath: \new-website-1123\src\views\special\fx101\index.vue
-->
<template>
  <div class="app-page">
    <div class="child-1">
      <div class="child-2">
        <page1></page1>
        <page2></page2>
      </div>
    </div>
    <page3 class="opacity-page"></page3>
    <page4 class="opacity-page"></page4>
    <div class="child-3 page5-top opacity-page">
      <div class="child-3-1">
        <page5></page5>
      </div>
    </div>

    <page6 class="opacity-page"></page6>
    <page7 class="opacity-page scale-page"></page7>

    <div class="child-3 page81-top opacity-page">
      <div class="child-3-1">
        <page81></page81>
      </div>
    </div>

    <div class="child-3 page8-top opacity-page">
      <div class="child-3-1">
        <page8></page8>
      </div>
    </div>

    <page82 class="opacity-page scale-page page82"></page82>
    <page82-mobile class="page82-mobile"></page82-mobile>

    <page9 class="opacity-page scale-page"></page9>
    <page10 class="opacity-page scale-page"></page10>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page81 from "./page81.vue";
import page8 from "./page8.vue";
import page82 from "./page82.vue";
import page82Mobile from "./page82Mobile.vue";
import page9 from "./page9.vue";
import page10 from "./page10.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page81,
    page8,
    page82,
    page82Mobile,
    page9,
    page10,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"56f1227c7d0a462cb8c70588b7144839"})
    },
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1A();
        this.page2A();
        this.page5A("5");
        this.page5A("81");
        this.page5A("8");
      }
    },
    page5A(type) {
      let div = document.querySelector(`.page${type}-top`);
      let page5 = document.querySelector(`.fx101-page${type}`);
      let line = page5.querySelectorAll(".line-span");
      let text = page5.querySelectorAll(".line-label");
      let a = this.scrollTop - div.offsetTop;
      if (a > 320) {
        let b = a - 320;
        let ra = (b / 280) * 16;
        for (let i = 0; i < text.length; i++) {
          const element = text[i];
          element.style.height = ra + "px";
        }
      } else {
        for (let i = 0; i < text.length; i++) {
          const element = text[i];
          element.style.height = 0 + "px";
        }
      }
      if (a > 200) {
        a = a - 200;
        for (let i = 0; i < line.length; i++) {
          const element = line[i];
          element.style.width = a + "px";
        }
      } else {
        for (let i = 0; i < line.length; i++) {
          const element = line[i];
          element.style.width = 0 + "px";
          if (text[i]) {
            text[i].style.height = 0;
          }
        }
      }
    },
    page2A() {
      let div = document.querySelector(".fx101-page2");
      let text = div.querySelector(".text-div");
      let filter = div.querySelector(".filter-div");
      /* if (this.scrollTop > 1600) {
        div.style.opacity = 0;
      } else*/ if (this.scrollTop > 1400) {
        let chaTop = this.scrollTop - 1400;
        let rateTop = chaTop / 700;
        let rateShow = chaTop / 400;
        let scale = (1 - rateTop) * 4;
        scale = scale < 1 ? 1 : scale;
        text.style.transform = `scale(${scale})`;
        text.style.opacity = rateShow;
      } else if (this.scrollTop > 1200) {
        let chaTop = this.scrollTop - 1200;
        let rateTop = chaTop / 200;
        filter.style.opacity = rateTop;
      } else if (this.scrollTop > 900) {
        let chaTop = this.scrollTop - 900;
        let rateTop = chaTop / 300;
        div.style.opacity = rateTop;
      } else if (this.scrollTop < 600) {
        div.style.opacity = 0;
        text.style.opacity = 0;
      }
    },
    page1A() {
      let div = document.querySelector(".fx101-page1");
      // let text = div.querySelector(".text-div");

      if (this.scrollTop > 200) {
        let c = 900 - this.scrollTop - 200;
        div.style.opacity = c / 700;
      } else {
        div.style.opacity = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-page {
  // height: calc(100vh * 10);
  position: relative;
  .child-1 {
    position: relative;
    top: 0;
    height: calc(200vh + 1800px);
  }
  .child-2 {
    position: sticky;
    top: 0;
    height: 100vh;
  }
  .child-3 {
    height: calc(100vh + 700px);
    &-1 {
      position: sticky;
      top: 0;
      height: 100vh;
    }
  }
  .page82-mobile {
    display: none;
  }
}
@media screen and (min-height: 800px) {
  .app-page {
    .child-1 {
      height: calc(200vh + 1350px);
    }
  }
}
@media screen and (max-height: 800px) {
  .app-page {
    .child-1 {
      height: calc(200vh + 1500px);
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .app-page {
    .child-1 {
      height: auto;
    }
    .child-2 {
      position: relative;
      height: auto;
    }
    .child-3 {
      height: auto;
      &-1 {
        position: relative;
        height: auto;
      }
    }

    .page82 {
      display: none;
    }
    .page82-mobile {
      display: block;
    }
  }
}
</style>
