<!--
 * @Author: your name
 * @Date: 2021-12-03 08:04:17
 * @LastEditTime: 2022-01-26 17:45:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page3.vue
-->
<template>
  <div class="fx101-page3">
    <div class="text-div">
      <div class="top-div">
        <div class="desc-s">
          <span>云脑物联、智能防逃、自动清虫、全身防水、</span>
          <span>AI控光、兼容多种光源、智能报警</span>
        </div>
        <div class="num-div">
          <cnum :number="99">捕虫率</cnum>
          <cnum :number="99">灭虫率</cnum>
          <cnum :number="0">逃跑率</cnum>
        </div>
      </div>
      <div class="desc-div">
        注：以上信息中，监测数据为特定试验环境下抽样监测结果，设备使用环境、使用方式及保养方式等对试验效果会有影响。
      </div>
    </div>

    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx301/6.png"
        alt="上太科技"
      />
    </div>
    <div class="p-24 mobile-desc">
      注：以上信息中，监测数据为特定试验环境下抽样监测结果，设备使用环境、使用方式及保养方式等对试验效果会有影响。
    </div>
  </div>
</template>

<script>
import cnum from "./components/number.vue";
export default {
  name: "",
  components: {
    cnum,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page3 {
  position: relative;
  height: 100vh;
  .img-div {
    position: absolute;
    height: 80%;
    top: 15%;
    left: 70%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .text-div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 11vw;
    padding-top: 260px;
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    .top-div {
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title-span {
      font-size: 60px;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      .model-span {
        font-size: 60px;
      }
      .label-span {
        font-size: 60px;
      }
    }
    .desc-s {
      display: flex;
      flex-direction: column;
      font-size: 36px;
      font-family: Microsoft YaHei;
      color: $color-text;
      line-height: 52px;
    }
    .num-div {
      display: flex;
      justify-content: space-between;
      padding-right: 80px;
    }
  }
  .desc-div {
    font-size: 14px;
    font-weight: 400;
    color: rgba($color: #fff, $alpha: 0.4);
  }
  .mobile-desc {
    display: none;
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page3 {
    position: relative;
    height: auto;
    .img-div {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      img {
        height: unset;
        width: 40%;
        object-fit: contain;
      }
    }
    .text-div {
      height: unset;
      position: relative;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: unset;
      text-align: center;
      .top-div {
        height: unset;
      }
      .title-span {
        font-size: 0.55rem;
        .model-span {
          font-size: 0.55rem;
        }
        .label-span {
          font-size: 0.55rem;
        }
      }
      .desc-s {
        width: 86%;
        margin: auto;
        font-size: 0.4rem;
        line-height: 0.55rem;
      }
      .num-div {
        width: 86%;
        margin: auto;
        margin-top: 0.36rem;
        padding-right: 0;
      }
      .desc-div {
        display: none;
      }
    }
    .p-24 {
      width: 86%;
      margin: auto;
    }
  }
}
</style>
