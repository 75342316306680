<!--
 * @Author: your name
 * @Date: 2021-12-28 16:45:23
 * @LastEditTime: 2023-02-10 15:55:15
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx301\page82Moile.vue
-->
<template>
  <div class="page8-mobile">
    <s-swiper
      :data-list="dataList"
      :title="{
        text: '外观精美',
        color: colorLabel,
      }"
      fit="contain"
      @change="swiperChange"
    ></s-swiper>
  </div>
</template>

<script>
import sSwiper from "../../programme/components/swiper.vue";
export default {
  name: "",
  components: {
    sSwiper,
  },
  data() {
    return {
      dataList: [
        {
          label: "正视图",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-bottom.png",
        },
        {
          label: "左视图",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-left.png",
        },
        {
          label: "右视图",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-right.png",
        },
        {
          label: "顶视图",
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-top.png",
        },
      ],
      colorLabel: "正视图",
    };
  },
  created() {},
  methods: {
    swiperChange(e) {
      this.colorLabel = this.dataList[e].label;
    },
  },
};
</script>

<style lang='scss' scoped>
.page8-mobile {
  width: 100%;
  overflow: hidden;
}
::v-deep {
  .programme-title {
    & > p {
      display: flex;
      flex-direction: column;
      .col-g {
        font-size: 0.36rem;
        margin-top: 8px;
      }
      img {
        object-fit: contain;
      }
    }
  }
}
</style>
