<!--
 * @Author: your name
 * @Date: 2021-12-03 09:13:47
 * @LastEditTime: 2021-12-15 11:37:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page8.vue
-->
<template>
  <div class="fx101-page82 pt80">
    <ctitle titleb="外观精美"></ctitle>
    <div class="img-div">
      <img class="data-img" :src="imgurl" alt="上太科技" />
    </div>

    <ul class="view-home">
      <li :class="{ active: change === 1 }" @click="selectStyle('just')">
        正视图
      </li>
      <li :class="{ active: change === 2 }" @click="selectStyle('left')">
        左视图
      </li>
      <li :class="{ active: change === 3 }" @click="selectStyle('right')">
        右视图
      </li>
      <li :class="{ active: change === 4 }" @click="selectStyle('top')">
        顶视图
      </li>
    </ul>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {
      imgurl: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-bottom.png",
      change: 1,
    };
  },
  created() {},
  methods: {
    selectStyle(item) {
      if (item == "just") {
        this.imgurl = "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-bottom.png";
        this.change = 1;
      } else if (item == "left") {
        this.imgurl = "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-left.png";
        this.change = 2;
      } else if (item == "right") {
        this.imgurl = "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-right.png";
        this.change = 3;
      } else if (item == "top") {
        this.imgurl = "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/fx301/cp-top.png";
        this.change = 4;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page82 {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-div {
    height: 80%;
    margin-top: 50px;
    position: relative;
    img {
      height: 100%;
    }
  }

  .view-home {
    color: rgba($color: #fff, $alpha: 0.5);
    margin-top: 60px;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.5);

    li {
      float: left;
      padding: 15px 0;
      margin-right: 50px;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      &:last-child {
        margin-right: 0;
      }
      &::after {
        position: absolute;
        content: "";
        font-size: 0;
        top: -2px;
        left: 0;
        width: 0%;
        height: 3px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        background: $color-active;
      }
      &.active,
      &:hover {
        color: $color-active;
        position: relative;
        &::after {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .fx101-page8 {
    .img-div {
      height: 68%;
    }
  }
}
@media screen and (min-height: 800px) {
  .fx101-page8 {
    .img-div {
      height: 78%;
    }
  }
}
</style>
